
module.exports =
  metaInfo:
    title: 'Экономия на досуге: развлекаемся и богатеем'
    meta: [
      { name: 'description', content: 'Учимся экономить на развлечениях, кино, театре, книгах и активном отдыхе с друзьями, семьёй и детьми.' }
      { name: 'keywords', content: 'экономия на развлечениях, как экономить на кино, как экономить на книгах, экономить на театре' }
      { name: 'human_title', content: 'Экономия на досуге: развлекаемся и богатеем' }
      { name: 'human_subtitle', content: 'Дешёво и сердито? Нет: экономно и весело! Учимся экономить на развлечениях, кино, театре, книгах и активном отдыхе с друзьями, семьёй и детьми.' }
    ]
